import session from 'next-session-client';
import * as myftUi from '@financial-times/n-myft-ui/myft';
import Expander from '@financial-times/o-expander';
import Video from '@financial-times/o-video';
import Clip from '@financial-times/cp-content-pipeline-ui/lib/components/content-tree/Clip/client';
import Audio from '@financial-times/o-audio';
import oDate from '@financial-times/o-date';

import componentTracking from './components/tracking/main';
import legalCopy from './components/legal-copy/main';
import * as seriesStickyNav from './components/series-sticky-nav';
import Feedback from './components/feedback';
import progressIndicator from './components/progress-indicator';
import initTables from './components/table/main';
import { initLiveBlogPost } from './components/live-blog-post';
import * as printButton from './components/print-button';
import workspaceNavInit from '@financial-times/workspace-menu';
import { getVideoAdTargeting } from './components/utils/videoAdTargeting';

// Page Kit
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';

// Tracking
import * as nTracking from '@financial-times/n-tracking';

import readyState from 'ready-state';
import scrollytelling from './components/scrollytelling';

// In Article Content Sign Up
import oOverlay from '@financial-times/o-overlay';
import signUpConfirmation from './components/sign-up/confirmation';

// Google Extended Access
import { getUserAndAccessExtendedInfo } from './helpers/google-extended-access';
import { isUserExtendedAccessEligible } from '../server/share/google-extended-access';

// insert SubX Script
import { insertSubXScript } from './helpers/insert-subx-script';
// insert user uuid in pagekit app context object
import { insertUserUuid } from './helpers/insert-user-uuid';
// Helper function to check if the FTConsent cookie contains behaviouraladsOnsite as 'on'
import { hasUserConsentedToBehaviouralTracking } from './helpers/checkBehaviouralAdsConsentCheck';

// Explore article recommendations
import * as explore from './components/explore/index';
import { getArticleId } from './components/utils/article';
import {
	initProFeaturesConfig,
	getActiveFeatures
} from './components/widgets-config';
import { initWorkspaceTeaser } from './components/workspace-teaser';
import '@financial-times/o3-tooltip';

// Ads
import { adsUsePGClient } from '../utils/ads';

// Right Hand Rail methods
import { cloneConceptsToRhr } from './components/right-hand-rail/main';

const SIGN_UP_CONFIRMATION_OVERLAY_ID = 'signUpConfirmationOverlay';
const clientOpts = [];

readyState.dom.then(async () => {
	const flags = pageKitFlags.init();
	const appContext = pageKitAppContext.init();
	const isSignedIn = Boolean(session.sessionId());
	const adsUsePG = adsUsePGClient(flags);

	if (flags.get('enableSubX') && hasUserConsentedToBehaviouralTracking()) {
		if (isSignedIn) {
			try {
				await insertUserUuid();
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(
					'Main: Failed to add user UUID to the Pagekit app context'
				);
			}
		}

		insertSubXScript();
	}

	if (
		flags.get('customCodeComponent') &&
		document.querySelectorAll('custom-code-component').length > 0
	) {
		import('@financial-times/cp-content-pipeline-ui/lib/client.js')
			.then((module) => {
				const { CustomCodeComponentClient } = module;
				if (CustomCodeComponentClient) {
					CustomCodeComponentClient.init();
				}
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error('Failed to load cp-custom-code-component client', error);
			});

		import(
			'@financial-times/custom-code-component/dist/custom-code-component.js'
		)
			.then(() => {})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error('Failed to load custom-code-component library', error);
			});
	}

	let oTracking;
	const headerOptions = flags.get('enhancedSearchTest')
		? {
				enhancedSearchUrl: 'https://enhanced-search.ft.com/?q=',
				searchState: 'open'
		  }
		: {};

	pageKitLayout.init({ headerOptions });

	if (flags.get('oTracking')) {
		oTracking = nTracking.init({ appContext: appContext.getAll() });
		componentTracking(oTracking);
	}

	if (flags.get('enterpriseSharingTooltipsAndLabels')) {
		import('./components/enterprise-share/intro-tooltip')
			.then(({ initSharingTooltip }) => initSharingTooltip())
			.then((willSeeSharingTooltip) => {
				import('./components/enterprise-share/with-highlights-tooltip').then(
					({ initSharingWithHighlightsTooltip }) => {
						// Only show this tooltip if we don't plan to show the sharing "intro" tooltip
						if (!willSeeSharingTooltip) {
							initSharingWithHighlightsTooltip();
						}
					}
				);
			});
	}

	//getting rootId to pass it to the subscription sign-up iframe to be consistent between root_id
	const trackingRootId = oTracking?.getRootID() || null;
	const signUpArticleIframe = document.getElementById('signUpIframe');
	if (signUpArticleIframe) {
		const url = new URL(
			signUpArticleIframe.getAttribute('data-prev-url'),
			window.location.origin
		);
		if (trackingRootId) {
			url.searchParams.append('trackingRootId', trackingRootId);
		}
		signUpArticleIframe.setAttribute('src', url.href);
	}

	if (flags.get('realUserMonitoringForPerformance')) {
		nTracking.trackers.realUserMonitoringForPerformance();
		nTracking.trackers.realUserMonitoringWithAttribution();
	}

	if (flags.get('optimizely') && flags.get('optimizelyArticle')) {
		// The Optimizely snippet attaches relevant experiment details to the window object

		let i = 0;
		const getActiveTests = () => {
			i++;
			const activeTests =
				window.FT &&
				window.FT.optimizelyAdditionalDetails &&
				window.FT.optimizelyAdditionalDetails.spoorTracking;
			if (activeTests) {
				activeTests.forEach((test) => {
					nTracking.broadcast('oTracking.event', {
						category: 'optimizely',
						action: 'experiment',
						...test
					});
				});
				clearInterval(intervalId);
			}
			if (i >= 5) {
				nTracking.broadcast('oTracking.event', {
					category: 'optimizely',
					action: 'no-experiment'
				});
				clearInterval(intervalId);
			}
		};
		const intervalId = setInterval(getActiveTests, 100);
	}

	if (flags.get('syndication')) {
		import('@financial-times/n-syndication').then(
			({ init: nSyndicationInit }) => {
				nSyndicationInit(flags);
			}
		);
	}

	if (flags.get('myFtApi')) {
		clientOpts.push({ relationship: 'followed', type: 'concept' });
		clientOpts.push({ relationship: 'saved', type: 'content' });
	}

	myftUi.client.init(clientOpts).catch((e) => {
		// Keeps console clean for anonymous users
		if (e !== 'No session cookie found') {
			throw e;
		}
	});

	if (flags.get('articleShareButtons')) {
		import('./components/share/main').then(({ init: shareInit }) => {
			shareInit(flags);
		});
	}

	workspaceNavInit({
		app: appContext.appName || 'article',
		enabled:
			!flags.get('myFtWorkspaceTest') && flags.get('enterpriseWorkspaceNav'),
		navTarget: '[data-component="nav-list--right"]',
		drawerTarget: '[data-component="drawer-menu--primary__drawer-menu-list"]',
		drawerDividerTarget: '[data-component="drawer-menu-item--divide"]'
	}).catch((error) => {
		// eslint-disable-next-line no-console
		console.error(
			'Workspace navigation failed to initialise, please inform LifeCycle team in Enterprise',
			error
		);
	});

	//strip out the imageId query parameter so it doesn't get duplicated when sharing graphics again
	if (
		window.location.search &&
		window.location.search.indexOf('imageId=') >= 0
	) {
		window.history.replaceState(
			null,
			null,
			window.location.pathname +
				window.location.search.replace(
					/&imageId(=[^&]*)?|^\?imageId(=[^&]*)?&?/,
					''
				)
		);
	}

	nTracking.trackers.pageAttention({ target: '.article__content' });

	if (flags.get('textCopyTracking')) {
		nTracking.trackers.textCopying();
	}

	legalCopy(flags);

	const proUserWithAnnotationsEditor = flags.get(
		'enterpriseUserAnnotationsEditor'
	);
	const articleSharedWithHighlights = new URL(
		window.location.href
	).searchParams.has('highlights');
	if (proUserWithAnnotationsEditor || articleSharedWithHighlights) {
		const initOptions = {
			readOnly: articleSharedWithHighlights,
			articleId: getArticleId(),
			showMostHighlightedParagraph: flags.get('showMostHighlightedParagraph')
		};
		import('@financial-times/user-annotations-editor').then(
			({ default: userAnnotationsEditor }) => {
				userAnnotationsEditor.init(initOptions);
			}
		);
	}

	if (flags.get('proArticleCustomisation')) {
		initProFeaturesConfig(getActiveFeatures(flags));
	}

	seriesStickyNav.init();

	if (document.querySelector('.o-expander__toggle')) {
		Array.from(
			document.body.querySelectorAll('[data-o-component="o-expander"]'),
			(rootEl) => {
				const userOptions = Expander._getDataAttributes(rootEl);

				const opts = Object.assign(userOptions, {
					selectors: {
						toggle: '.o-expander__toggle',
						content: '.o-expander__content',
						item: userOptions.itemSelector || 'li'
					},
					classnames: {
						initialized: 'o-expander--initialized',
						inactive: 'o-expander--inactive',
						expanded: 'o-expander__content--expanded-next-article',
						collapsed: 'o-expander__content--collapsed',
						collapsibleItem: 'o-expander__collapsible-item'
					}
				});

				return Expander.createCustom(rootEl, opts);
			}
		);
	}

	myftUi.ui.init({
		anonymous: !isSignedIn,
		flags
	});

	if (flags.get('myFTInstantAlertsOnboarding')) {
		import('@financial-times/n-myft-ui/components/jsx/preferences-modal').then(
			({ default: preferencesModal }) => {
				preferencesModal();
			}
		);
		import(
			'@financial-times/n-myft-ui/components/jsx/follow-plus-instant-alerts'
		).then(({ default: followPlusInstantAlerts }) => {
			followPlusInstantAlerts();
		});
	}

	oDate.init();

	[...document.querySelectorAll('[data-o-component="o-video"]')].forEach(
		(videoEl) => {
			new Video(videoEl, {
				advertising: flags.get('ads') && flags.get('videoPlayerAdvertising'),
				placeholder: true,
				optimumwidth: 700,
				optimumvideowidth: 1280,
				systemcode: 'next-article',
				targeting: getVideoAdTargeting(flags)
			});
		}
	);
	if (flags.get('clipsEnhancedPlayer')) {
		Clip.init();
	}

	// video anchors are added for core experience only
	// remove video anchors when JS is enabled
	document
		.querySelectorAll('a[data-asset-type="video"][data-embedded="true"]')
		.forEach((videoAnchorEl) => {
			videoAnchorEl.parentNode.removeChild(videoAnchorEl);
		});

	[...document.querySelectorAll('[data-o-component="o-audio"]')].forEach(
		(audioEl) => {
			new Audio(audioEl);
		}
	);

	if (isSignedIn && flags.get('magnetOnArticle')) {
		import('@financial-times/n-magnet')
			.then(({ init }) => init({ flags, oTracking }))
			.catch((error) => {
				// fail silently
				// eslint-disable-next-line no-console
				console.debug('failed to init magnet', error);
			});
	}

	if (
		flags.get('articleComments') &&
		document.querySelector('#o-comments-stream')
	) {
		import('./components/comments').then(({ init: commentsInit }) => {
			commentsInit(flags);
		});
	}

	if (flags.get('exponeaOnsiteMessaging')) {
		import('@financial-times/n-exponea').then(({ init }) => {
			init();
		});
	}

	if (
		!flags.get('adsDisableInternalCMP') &&
		(flags.get('messageSlotBottom') || flags.get('messageSlotTop'))
	) {
		import('@financial-times/n-messaging-client/main-client').then(
			({ nMessagingClient }) => {
				nMessagingClient.init();
			}
		);
	}

	if (flags.get('articleRelatedContent')) {
		if (explore.canShowExplore(flags)) {
			try {
				explore.init(flags);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		} else {
			import('./components/onward-journey').then(
				({ init: onwardJourneyInit }) => {
					onwardJourneyInit(flags).catch((err) =>
						import('@financial-times/o-errors').then((oErrors) =>
							oErrors.default.report(err)
						)
					);
				}
			);
		}
	}

	if (document.querySelector('.twitter-tweet')) {
		import('./components/embedded-tweets').then(
			({ default: initialiseEmbeddedTweets }) => {
				initialiseEmbeddedTweets();
			}
		);
	}

	progressIndicator();

	if (flags.get('articlePageWorkspaceLink')) {
		initWorkspaceTeaser();
	}

	if (flags.get('onboardingMessaging') === 'followPlusEmailDigestTooltip') {
		import('./components/onboarding-tooltip').then(
			({ init: onboardingTooltipInit }) => {
				onboardingTooltipInit();
			}
		);
	}

	new Feedback('article-summary');

	if (flags.get('articleInstantAlertsPromo')) {
		import('./components/pre-body-promotional-banner/main')
			.then(({ init }) => init())
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error(error);
			});
	}

	scrollytelling(flags);

	if (flags.get('useFlourish')) {
		import('./components/flourish/replace-graphics').then(
			({ default: replaceWithFlourish }) => {
				replaceWithFlourish(appContext);
			}
		);
	}

	if (flags.get('ads')) {
		if (adsUsePG) {
			cloneConceptsToRhr();
		} else {
			import(`./components/ads/index.js`).then(({ init: adsInit }) => {
				adsInit(appContext, flags);
			});
		}
	}

	initLiveBlogPost();

	initTables();

	printButton.init();

	// In article content sign up confirmation popup using origami o-overlay component
	const pageOverlays = oOverlay.init();
	const signUpConfirmationOverlay = pageOverlays.find(
		(overlay) => overlay.id === SIGN_UP_CONFIRMATION_OVERLAY_ID
	);

	if (signUpConfirmationOverlay) {
		// render the popup
		signUpConfirmationOverlay.open();

		// attaching submit button behaviour after the overlay ends rendering
		document.addEventListener('oOverlay.ready', (overlayReadyEvent) => {
			if (overlayReadyEvent.detail.el.id === SIGN_UP_CONFIRMATION_OVERLAY_ID) {
				signUpConfirmation(signUpConfirmationOverlay);
			}
		});
	}

	if (flags.get('inArticleContentSignUp')) {
		import('./components/sign-up/details').then(
			({ addSignupDetailsListeners, registerOnLoadHandler }) => {
				registerOnLoadHandler();
				addSignupDetailsListeners();
			}
		);
	}

	// // Google Extended Access initialization
	if (isUserExtendedAccessEligible(window.location.search, flags.flags)) {
		getUserAndAccessExtendedInfo(appContext.appContext)
			.then(({ userState, showcaseEntitlement }) =>
				window.InitGaaMetering(userState, showcaseEntitlement)
			)
			.catch((err) =>
				import('@financial-times/o-errors').then((oErrors) =>
					oErrors.default.report(err)
				)
			);
	}

	// Extensible Frontends Initialization
	if (flags.get('extensibleFrontends')) {
		import('ef-runtime-client').then(({ init: EFRuntimeInit }) =>
			EFRuntimeInit({
				systemCode: 'next-article'
			})
		);
	}

	// Amplitude Experiment temporary test
	const amplitudeExperimentAAVariant = flags.get('amplitude-experiment-aa');
	if (amplitudeExperimentAAVariant) {
		nTracking.broadcast('oTracking.event', {
			category: 'amplitudeExperiment',
			action: 'exposure',
			event_properties: {
				flag_key: 'amplitude-experiment-aa',
				variant: amplitudeExperimentAAVariant,
				experiment_key: 'exp-1'
			}
		});
	}
});
